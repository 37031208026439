import React from "react";
import { Link } from "react-router-dom";

function Product({ product }) {
  const style = {
    product: "w-[90%] h-[auto] m-[auto] grid grid-cols-3 place-items-center",
    products:
      "w-[350px] h-[60vh] -[auto] mr-[auto] mt-[15px] mb-[15px] text-left",
    productImg: "w-[400px] h-[300px] object-cover mb-[15px]",
  };
  return (
    <div className={style.product}>
      <Link
        className={style.products}
        state={product}
        to={`/single/${product.id}`}
      >
        <div>
          <img
            className={style.productImg}
            src={product.data.productImage}
            alt=""
          />
          <h1 className="text-[25px] font-bold">
            {product.data.productDescription}
          </h1>
          <p className="text-[18px] font-semibold">
            ${product.data.productPrice}
          </p>
        </div>
      </Link>
    </div>
  );
}

export default Product;

// import React from "react";

// function Product({ product }) {
//   return (
//     <div>
//       <img src={product.data.productImage} alt="" />
//       <h1>{product.data.productName}</h1>
//       <p>${product.data.productWeight}</p>
//     </div>
//   );
// }

// export default Product;
