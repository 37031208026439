import React from "react";
import { useNavigate } from "react-router-dom";
import { storage, firestore } from "../firebase/firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  doc,
  setDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

function Admin() {
  document.title = "Admin";
  const navigate = useNavigate();
  const AddtoProduct = async (e) => {
    e.preventDefault();
    const productName = e.target[0].value;
    const productPrice = e.target[1].value;
    const productDescription = e.target[2].value;
    const productImg = e.target[3].files[0];
    const productSale = e.target[5].value;
    console.log(
      productName,
      productPrice,
      productDescription,
      productImg,
      productSale
    );
    try {
      const storageRef = ref(storage, productName);
      const uploadTask = uploadBytesResumable(storageRef, productImg);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await addDoc(collection(firestore, "products"), {
              productName: productName,
              productPrice: Number(productPrice),
              productDescription: productDescription,
              productImage: downloadURL,
              productSale: Number(productSale),
              date: serverTimestamp(),
            });
            navigate("/shop");
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const style = {
    form: "flex flex-col items-center",
    addproduct: "text-[40px] pt-[20px] font-bold",
    input:
      "w-[400px] h-[50px] mt-[50px] indent-[10px] border border-black shadow-2xl",
    btn: "w-[200px] h-[40px] bg-[dodgerblue] mt-[50px]",
  };

  return (
    <div className={style.admin}>
      <form onSubmit={AddtoProduct} className={style.form}>
        <h1 className={style.addproduct}>ADD PRODUCTS</h1>
        <input type="text" placeholder="Product Name" className={style.input} />
        <input
          type="number"
          placeholder="Product Price"
          className={style.input}
        />
        <input
          type="text"
          placeholder="Product Description"
          className={style.input}
        />
        <input
          type="file"
          placeholder="Product Image"
          className={style.input}
        />
        <input
          type="number"
          placeholder="Product Sale"
          className={style.input}
        />
        <button className={style.btn}>Upload</button>
      </form>
    </div>
  );
}

export default Admin;
