import React from "react";

function HoverImg() {
  const style = {
    HoverImg: "w-[1031px] h-[396px] flex lg:w-[1665px] lg:h-[650px]",
    first: "w-[349px] h-[396px] bgIMGhoverIMG lg:w-[554.99px] lg:h-[650px]",
    second: "w-[349px] h-[396px] bgIMGhoverIMG2 lg:w-[554.99px] lg:h-[650px]",
    third: "w-[349px] h-[396px] bgIMGhoverIMG3 lg:w-[554.99px] lg:h-[650px]",
  };
  return (
    <div className={style.HoverImg}>
      <div className={style.first}></div>
      <div className={style.second}></div>
      <div className={style.third}></div>
    </div>
  );
}

export default HoverImg;
