import React from "react";

function Adverstingtwo() {
  const style = {
    Adverstingtwo:
      "w-[1031px] h-[396px] bgAdversting2IMG text-left lg:w-[1665px] lg:h-[655px]",
    Adverstingtwoh1:
      "text-[30px] pl-[600px] pt-[100px] text-[white] font-extrabold lg:pt-[200px] lg:pl-[900px] lg:text-[50px]",
    btn: "w-[230px] h-[50px] ml-[600px] text-[14px] font-bold mt-[20px] bg-[#f6f6f6] lg:w-[230px] lg:h-[50px] lg:ml-[900px] lg:mt-[20px]",
  };
  return (
    <div className={style.Adverstingtwo}>
      <h1 className={style.Adverstingtwoh1}>BACK IN THE GAME.</h1>
      <p className="w-[350px] ml-[600px] text-white mt-[20px] lg:ml-[900px] lg:mt-[20px]">
        You asked, we answered. Skullcandy Gaming is back with 3 all-new, badass
        gaming headsets to cover every budget and skill level.
      </p>
      <button className={style.btn}>SEE SKULLCANDY GAMING</button>
    </div>
  );
}

export default Adverstingtwo;
