import React, { useState } from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { Link } from "react-router-dom";

function Login() {
  document.title = "Sign in";
  const style = {
    Login: "absolute  w-[1665px] top-[88px] h-[75vh] flex flex-col",
    text: "text-[50px] font-extrabold pt-[30px]",
    signin: "flex w-full h-auto mt-[42px]",
    left: "w-[50%] h-[485px] ",
    right: "w-[50%] h-[485px] flex justify-center items-center",
    signinform: "flex flex-col text-left mt-[90px]",
    input:
      "border border-[#DDDDDD] w-[500px] h-[50px] ml-[200px] mt-[10px] bg-[#f6f6f6] indent-8 outline-[#001]",
    inputtext: "pl-[200px] pt-[10px]",
    btn: "w-[132px] h-[50px] bg-[#383838] text-white",
    create_account: "w-[590px] h-[340px] bg-[#ddd] flex flex-col text-left",
    create_account_h1: "text-[20px] pt-[40px] pl-[40px] font-bold",
    registerbtn:
      "w-[205px] h-[50px] bg-[#2C2C2D] text-white ml-[40px] mt-[15px]",
  };

  const [error, seterror] = useState(false);
  const Signin = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    try {
      signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      seterror(true);
    }
  };
  return (
    <div>
      <Navbar />
      <Header />
      <div className={style.Login}>
        <div>
          <h1 className={style.text}>SIGN IN</h1>
        </div>
        <div className={style.signin}>
          <div className={style.left}>
            <form onSubmit={Signin} className={style.signinform}>
              <p className={style.inputtext}>Email Address:</p>
              <input className={style.input} type="email" required />
              <p className={style.inputtext}>Password:</p>
              <input className={style.input} type="password" required />
              <div className="flex ml-[200px] mt-[30px]">
                <input type="checkbox" name="" id="" />
                <span className="pl-[10px]">Sign up for Newsletter</span>
              </div>
              <div className="flex ml-[300px] mt-[20px] justify-center items-center">
                <button className={style.btn}>SIGN IN</button>
                <span className="pl-[30px]">Forgot your password?</span>
              </div>
              <span>{error && <h1>Nimadir hato?</h1>}</span>
            </form>
          </div>
          <div className={style.right}>
            <div className={style.create_account}>
              <h1 className={style.create_account_h1}>NEW CUSTOMER?</h1>
              <p className="pl-[40px] pt-[10px]">
                Create an account with us and you'll be able to:
              </p>
              <ul className="ml-[90px]">
                <li className="pt-[10px] list-disc	">Check out faster</li>
                <li className="pt-[10px] list-disc	">
                  Save multiple shipping addresses
                </li>
                <li className="pt-[10px] list-disc	">
                  Access your order history
                </li>
                <li className="pt-[10px] list-disc	">Track new orders</li>
              </ul>
              <button className={style.registerbtn}>
                <Link to={"/register"}>Create Account</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
