import React from "react";
import Adverstingone from "../components/Adverstingone";
import Adverstingtwo from "../components/Adverstingtwo";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HoverImg from "../components/HoverImg";
import Navbar from "../components/Navbar";

function Home() {
  return (
    <div>
      <Navbar />
      <Header />
      <Banner />
      <HoverImg />
      <Adverstingone />
      <Adverstingtwo />
      <Footer />
    </div>
  );
}

export default Home;
