import React from "react";
import Navbar from "../components/Navbar";

function InsideSkullcandy() {
  document.title = "Inside Skullcandy";
  const style = {
    InsideSkullcandy:
      "w-[100%] h-[899px] InsideSckullcandy absolute top-[58px] text-left",
    InsideSkullcandyh1:
      "text-[white] font-extrabold text-[50px] w-[503px] text-left mt-[200px] ml-[200px]",
    InsideSkullcandyp: "text-left text-white pl-[200px]",
    InsideSkullcandybtn:
      "w-[170px] h-[50px] bg-[#f6f6f6] font-bold ml-[200px] mt-[20px]",
    InsideSkullCandyOne:
      "w-[100%] h-[607px] bgHoverIMG2 absolute top-[957px] text-left overflow-hidden duration-[.3s]",
    InsideSkullcandyOneh1:
      "text-[white] font-extrabold text-[50px] w-[503px] text-left mt-[200px] ml-[1150px] ",
    InsideSkullcandyOnep: "text-left text-white ml-[1150px]",
    InsideSkullcandyOnebtn:
      "w-[170px] h-[50px] bg-[#f6f6f6] font-bold ml-[1150px] mt-[20px]",
    InsideSkullCandyTwo:
      "w-[100%] h-[607px] bgHoverIMG absolute top-[1564px] text-left",
    InsideSkullcandyTwoh1:
      "text-[white] font-extrabold text-[50px] w-[503px] text-left mt-[200px] ml-[250px] ",
    InsideSkullcandyTwop: "text-left text-white ml-[250px]",
    InsideSkullcandyTwobtn:
      "w-[170px] h-[50px] bg-[#f6f6f6] font-bold ml-[250px] mt-[20px]",
    InsideSkullCandyThree:
      "w-[100%] h-[607px] bgHoverIMG3 absolute top-[2171px] text-left",
    InsideSkullcandyThreeh1:
      "text-[white] font-extrabold text-[50px] w-[503px] text-left mt-[200px] ml-[1150px] ",
    InsideSkullcandyThreep: "text-left text-white ml-[1150px]",
    InsideSkullcandyThreebtn:
      "w-[170px] h-[50px] bg-[#f6f6f6] font-bold ml-[1150px] mt-[20px]",
    InsideSkullCandyFour:
      "w-[100%] h-[607px] bgHoverIMG4 absolute top-[2778px] text-left",
    InsideSkullcandyFourh1:
      "text-[white] font-extrabold text-[50px] w-[503px] text-left mt-[200px] ml-[250px] ",
    InsideSkullcandyFourp: "text-left text-white ml-[250px]",
    InsideSkullcandyFourbtn:
      "w-[170px] h-[50px] bg-[#f6f6f6] font-bold ml-[250px] mt-[20px]",
  };
  return (
    <div>
      <Navbar />
      <div className={style.InsideSkullcandy}>
        <h1 className={style.InsideSkullcandyh1}>
          TO UNLEASH THE VISCERAL POWER OF MUSIC FOR ALL.
        </h1>
        <p className={style.InsideSkullcandyp}>
          Understand our Mission, Vision and Values.
        </p>
        <button className={style.InsideSkullcandybtn}>LEARN MORE</button>
      </div>

      <div className={style.InsideSkullCandyOne}>
        <h1 className={style.InsideSkullcandyOneh1}>
          OUR MISSION, VISION <br /> AND VALUES.
        </h1>
        <p className={style.InsideSkullcandyOnep}>
          See the principles that guide the way we work and play.
        </p>
        <button className={style.InsideSkullcandyOnebtn}>LEARN MORE</button>
      </div>
      <div className={style.InsideSkullCandyTwo}>
        <h1 className={style.InsideSkullcandyTwoh1}>OUR CULTURE</h1>
        <p className={style.InsideSkullcandyTwop}>
          Get a look at our unique office life in Park City, Utah.
        </p>
        <button className={style.InsideSkullcandyTwobtn}>LEARN MORE</button>
      </div>
      <div className={style.InsideSkullCandyThree}>
        <h1 className={style.InsideSkullcandyThreeh1}>
          OUR CHARITABLE <br /> PARTNERSHIPS.
        </h1>
        <p className={style.InsideSkullcandyThreep}>
          Learn how Skullcandy is doing well by doing good.
        </p>
        <button className={style.InsideSkullcandyThreebtn}>LEARN MORE</button>
      </div>
      <div className={style.InsideSkullCandyFour}>
        <h1 className={style.InsideSkullcandyFourh1}>FIND YOUR FREQUENCY.</h1>
        <p className={style.InsideSkullcandyFourp}>
          Meet the team that will help you find your own frequency.
        </p>
        <button className={style.InsideSkullcandyFourbtn}>LEARN MORE</button>
      </div>
    </div>
  );
}

export default InsideSkullcandy;
