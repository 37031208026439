import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Shop from "./pages/Shop";
import Admin from "./admin/Admin";
import InsideSkullcandy from "./pages/InsideSkullcandy";
import Singlepage from "./pages/Singlepage";
import Cart from "./pages/Cart";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="single/:id" element={<Singlepage />} />
          <Route path="/inside-skullcandy" element={<InsideSkullcandy />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/cart" element={<Cart />} />
          <Route
            path="*"
            element={
              <h1 className="text-center text-[200px] pt-[300px]">404 ERROR</h1>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
