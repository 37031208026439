import React, { useState } from "react";
import { AiOutlineUser, AiOutlineSearch } from "react-icons/ai";
import { BsBag } from "react-icons/bs";
import { Link } from "react-router-dom";
import admin from "../images/admin.png";
import Cart from "./Cart";

function Navbar() {
  const [navbar, setNavbar] = useState();
  const changebackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  document.addEventListener("scroll", changebackground);
  const [modal, setModal] = useState(false);
  const [modalinline, setModalinline] = useState(false);
  const [cart, setCart] = useState(false);
  const style = {
    navbar:
      "w-[1024px] h-[58px] bg-[#000] flex items-center z-50 fixed absolute border-b border-[#2c2c2d] lg:w-[1665px] index-30",
    active:
      "w-[1665px] h-[58px] fixed bg-[#000] flex items-center z-10 border-b border-[#2c2c2d]",
    logo: "w-[180px] h-[33px] ml-[115px] flex items-center",
    img: "w-[33px] h-[33px]",
    logotext: "text-white text-[30px] font-bold duration-[.3s]",
    active2: "hidden ",
    collection: "flex text-white ml-[20px]",
    collection2: "flex text-white ml-[-150px]",
    item: "text-[13px] ml-[10px] h-[58px] flex justify-center items-center cursor-pointer",
    rightnavbar:
      "w-[250px] h-[50px] ml-[200px] justify-center flex items-center lg:ml-[800px]",
    rightnavbar2:
      "w-[250px] h-[50px] ml-[970px] justify-center flex items-center",
    language: "w-[23px] h-[23px] ml-[20px]",
    modalshop: `bg-[#fff] w-[1665px] h-[60vh] z-0	 border border-black absolute top-[58px] flex justify-center items-center ${
      modal ? "translate-y-[0vh]" : "translate-y-[-70vh]"
    } duration-[0.3s]`,
    first: "w-[200px] h-[300px] flex items-center ml-[20px]",
    second: "w-[200px] h-[300px] flex items-center ml-[20px]",
    third: "w-[200px] h-[300px] flex items-center ml-[20px]",
    fourth: "w-[200px] h-[300px] flex items-center ml-[20px]",
    fifth: "w-[200px] h-[300px] flex ml-[20px]",
    sixth: "w-[300px] h-[300px]  flex-col justify-center flex ml-[20px]",
    itemshopmodal: "hover:text-[#969696] cursor-pointer text-left",
    inside_page: `w-[1665px] bg-[#fff] h-[50vh] absolute mt-[478px] flex ${
      modalinline ? "translate-y-[0px]" : "translate-y-[-478px]"
    } duration-[0.3s]`,
    fourthinline: "w-[333px] h-[50vh] border border-black",
    fifthinline: "w-[333px] h-[50vh] border border-black",
    firstinline: "w-[333px] h-[50vh] border border-black",
    secondinline: "w-[333px] h-[50vh] border border-black",
    thirdinline: "w-[333px] h-[50vh] border border-black",
  };
  return (
    <div className={navbar ? (style.navbar, style.active) : style.navbar}>
      <div className={style.logo}>
        <img
          className={style.img}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCJMziFHXqV9Z-5aQzLBtvxxeH_PqxopopHeDoQKLHjsZvvwdDEkBnEmFvD2Vkuatr1IQ&usqp=CAU"
          alt=""
        />
        <h1
          className={navbar ? (style.logotext, style.active2) : style.logotext}
        >
          Skullcandy
        </h1>
      </div>
      <ul
        className={
          navbar ? (style.collection, style.collection2) : style.collection
        }
      >
        <li
          className={style.item}
          onMouseEnter={() => setModal(true)}
          onMouseLeave={() => setModal(false)}
        >
          <Link to={"/shop"}>SHOP</Link>
        </li>
        <li
          className={style.item}
          onMouseEnter={() => setModalinline(true)}
          onMouseLeave={() => setModalinline(false)}
        >
          <Link to={"/inside-skullcandy"}>INSIDE SKULLCANDY</Link>
        </li>
      </ul>
      <div
        className={
          navbar ? (style.rightnavbar, style.rightnavbar2) : style.rightnavbar
        }
      >
        <p className="text-[13px] text-[#969696]">Support</p>
        <img
          className={style.language}
          src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/stencil/7c94ffd0-1fe7-013b-31ea-1e15635688c9/e/2dcfde50-f407-0135-4ccd-525400970412/img/US_EN_FLAG.png"
          alt=""
        />
        <Link className="text-white pl-[10px]" to={"/login"}>
          {localStorage.getItem("Name") ? (
            localStorage.getItem("Name")
          ) : (
            <AiOutlineUser className="ml-[10px] text-[20px] text-[white]" />
          )}
        </Link>
        <AiOutlineSearch className="ml-[20px] text-[20px] text-[white]" />
        <BsBag
          onClick={() => (cart ? setCart(false) : setCart(true))}
          className="ml-[20px] text-[20px] text-[white]"
        />
      </div>
      <div
        className={style.modalshop}
        onMouseEnter={() => setModal(true)}
        onMouseLeave={() => setModal(false)}
      >
        <div className={style.first}>
          <ul>
            <li className={style.itemshopmodal}>New Arrivals</li>
            <li className={style.itemshopmodal}>Skull-iQ Products</li>
            <li className={style.itemshopmodal}>Corporate Sales</li>
            <li className={style.itemshopmodal}>Sale</li>
          </ul>
        </div>
        <div className={style.second}>
          <ul>
            <li className="text-[20px] font-bold  pr-[90px]">Earbuds</li>
            <li className={style.itemshopmodal}>True Wireless Earbuds</li>
            <li className={style.itemshopmodal}>Wired Earbuds</li>
            <li className={style.itemshopmodal}>Shop All Earbuds</li>
          </ul>
        </div>
        <div className={style.third}>
          <ul>
            <li className="text-[20px] font-bold pr-[40px]">Headphones</li>
            <li className={style.itemshopmodal}>Wireless Headphones</li>
            <li className={style.itemshopmodal}>Wired Headphones</li>
            <li className={style.itemshopmodal}>Shop All Headphones</li>
          </ul>
        </div>
        <div className={style.fourth}>
          <ul>
            <li className="text-[20px] font-bold  pr-[80px]">Gaming</li>
            <li className={style.itemshopmodal}>Gaming Headsets</li>
            <li className={style.itemshopmodal}>Gaming Accessories</li>
            <li className={style.itemshopmodal}>Shop All Gaming</li>
          </ul>
        </div>
        <div className={style.fifth}>
          <ul>
            <li className="text-[20px] font-bold pl-[40px] pt-[90px] text-center">
              Accessories
            </li>
          </ul>
        </div>
        <div className={style.sixth}>
          <img
            src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/content/navigation/2020/shop/desktop-dropdown-featured-product.jpg?t=skdy"
            alt=""
          />
          <p className={style.itemshopmodal}>Shop Skullcandy Gaming</p>
        </div>
      </div>
      <div
        className={style.inside_page}
        onMouseEnter={() => setModalinline(true)}
        onMouseLeave={() => setModalinline(false)}
      >
        <div className={style.firstinline}>
          <img
            className="h-[30vh] hover:h-[31vh] hover:scale-[1] duration-[0.3s]"
            src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/content/navigation/2020/inside-skullcandy/desktop-dropdown-1.jpg?t=skdy"
            alt=""
          />
          <h1 className="text-[30px] font-extrabold text-[right] pt-[20px]">
            MUSIC WITH A <br /> MISSION
          </h1>
        </div>
        <div className={style.secondinline}>
          <img
            className="h-[30vh] hover:h-[31vh] hover:scale-[1] duration-[0.3s]"
            src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/content/navigation/2020/inside-skullcandy/desktop-dropdown-2.jpg?t=skdy"
            alt=""
          />
          <h1 className="text-[30px] font-extrabold text-[right] pt-[20px]">
            FIND YOUR <br /> FREQUENCY
          </h1>
        </div>
        <div className={style.thirdinline}>
          <img
            className="h-[30vh] hover:h-[31vh] hover:scale-[1] duration-[0.3s]"
            src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/content/navigation/2020/inside-skullcandy/desktop-dropdown-3.jpg?t=skdy"
            alt=""
          />
          <h1 className="text-[30px] font-extrabold text-[right] pt-[20px]">
            OUR MISSION, VISION, <br /> AND VALUES
          </h1>
        </div>
        <div className={style.fourthinline}>
          <img
            className="h-[30vh] hover:h-[31vh] hover:scale-[1] duration-[0.3s]"
            src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/content/navigation/2020/inside-skullcandy/desktop-dropdown-4.jpg?t=skdy"
            alt=""
          />
          <h1 className="text-[30px] font-extrabold text-[right] pt-[20px]">
            OUR CULTURE
          </h1>
        </div>
        <div className={style.fifthinline}>
          <img
            className="h-[30vh] hover:h-[31vh] hover:scale-[1] duration-[0.3s]"
            src="https://cdn11.bigcommerce.com/s-k11cg5mzh9/content/navigation/2020/inside-skullcandy/desktop-dropdown-5.jpg?t=skdy"
            alt=""
          />
          <h1 className="text-[30px] font-extrabold text-[right] pt-[20px]">
            OUR SUSTAINABLE <br /> EFFORTS
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
