import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, auth, firestore } from "../firebase/firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

function Register() {
  document.title = "Register";
  const [error, seterror] = useState(false);
  const style = {
    Register:
      "absolute w-[1665px] h-[90vh] top-[88px] flex flex-col items-center",
    Registerh1: "text-[50px] font-bold pt-[40px]",
    signupform: "text-left",
    input:
      "border border-[#DDDDDD] w-[500px] h-[50px] mt-[10px] bg-[#f6f6f6] indent-8 outline-[#000b]",
    inputtext: "pt-[10px]",
    btn: "w-[202px] h-[50px] bg-[#383838] text-white ml-[150px] mt-[20px]",
    inputfile:
      "bg-[#f6f6f6] indent-8 outline-[#000b] absolute top-[10px] opacity-[0]",
    inputfilediv:
      "relative mt-[30px] border border-[#DDDDDD] w-[500px] h-[50px] mt-[10px] bg-[#f6f6f6] indent-8 outline-[#000b]",
  };

  const Signup = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    const name = e.target[2].value;
    const lastname = e.target[3].value;
    const number = e.target[4].value;
    const file = e.target[5].files[0];

    const res = await createUserWithEmailAndPassword(auth, email, password);
    localStorage.setItem("Name", name);
    localStorage.setItem("Email", email);
    localStorage.setItem("Password", password);

    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    try {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateProfile(res.user, {
              name,
              photoURL: downloadURL,
            });
            await setDoc(doc(firestore, "users", res.user.uid), {
              name,
              email,
              number: number,
              lastname: lastname,
              photoURL: downloadURL,
              uid: res.user.uid,
            });
            localStorage.setItem("photoURL", downloadURL);
            console.log("File available at", downloadURL);
          });
          Navigate("/");
        }
      );
    } catch (error) {
      seterror(true);
    }
  };
  return (
    <div>
      <Navbar />
      <Header />
      <div className={style.Register}>
        <h1 className={style.Registerh1}>NEW ACCOUNT</h1>
        <form className={style.signupform} onSubmit={Signup}>
          <p className={style.inputtext}>Email Address</p>
          <input className={style.input} type="email" />
          <p className={style.inputtext}>Password</p>
          <input className={style.input} type="password" />
          <p className={style.inputtext}>First Name</p>
          <input className={style.input} type="text" />
          <p className={style.inputtext}>Last Name</p>
          <input className={style.input} type="text" />
          <p className={style.inputtext}>Phone Number</p>
          <input className={style.input} type="number" name="" id="" />
          <div className={style.inputfilediv}>
            <p className={style.inputtext}>Your Photo</p>
            <input className={style.inputfile} type="file" name="" id="" />
          </div>
          <div className="mt-[30px]">
            <input type="checkbox" name="" id="" />
            <span className="pl-[10px]">Sign up for Newsletter</span>
          </div>
          <button className={style.btn}>Create Account</button>
        </form>
      </div>
    </div>
  );
}

export default Register;
