import React from "react";

function Banner() {
  const style = {
    Banner: "h-[639px] w-[1024px] flex lg:h-[784px] lg:w-[1665px]",
    left: "w-[682px] h-[639px] bgIMG lg:w-[1110px] lg:h-[784px]",
    right: "w-[555px] h-[784px] flex flex-col",
    first: "w-[441px] h-[320px] bgIMGfirst lg:w-[555px] lg:h-[392px]",
    second: "w-[441px] h-[320px] bgIMGsecond lg:w-[555px] lg:h-[392px]",
    h1: "text-[40px] w-[230px] mt-[90px] ml-[90px] block text-left text-white font-extrabold",
    p: "w-[500px] ml-[90px] text-left text-white",
    btn: "w-[180px] h-[50px] bg-[#f6f6f6] font-bold ml-[-225px] mt-[20px] lg:ml-[-750px]",
    first_h1: "text-[18px] text-white font-extrabold pt-[90px] lg:pt-[90px]",
    first_p: "text-white",
  };
  return (
    <div className={style.Banner}>
      <div className={style.left}>
        <h1 className={style.h1}>GET INTO THE GAME.</h1>
        <p className={style.p}>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae
          molestiae ipsam voluptate perspiciatis consequatur dolores molestias
          id nihil ipsum architecto!
        </p>
        <button className={style.btn}>MEET SLYR</button>
      </div>
      <div className={style.right}>
        <div className={style.first}>
          <h1 className={style.first_h1}>SKULLCANDY GAMING IS BACK.</h1>
          <p className={style.first_p}>Celebrate the Return {">"}</p>
        </div>
        <div className={style.second}>
          <h1 className={style.first_h1}>TRUE FREEDOM, AMPLIFIED.</h1>
          <p className={style.first_p}>Shop Indy Evo {">"}</p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
