import React from "react";

function Adverstingone() {
  const style = {
    Adverstingone:
      "w-[1031px] h-[396px] bgAdverstingIMG text-left lg:w-[1665px] lg:h-[655px]",
    Adverstingoneh1:
      "text-[30px] pl-[50px] pt-[50px] text-[white] font-extrabold lg:text-[50px] lg:pl-[100px] lg:pt-[100px]",
    btn: "w-[180px] h-[50px] bg-[red] ml-[50px] text-[14px] font-bold mt-[20px] bg-[#f6f6f6] lg:ml-[100px]",
  };
  return (
    <div className={style.Adverstingone}>
      <h1 className={style.Adverstingoneh1}>
        WELCOME TO <br /> FUTURE-PROOF <br /> AUDIO.
      </h1>
      <p className="pl-[50px] text-white pt-[20px] lg:pl-[100px]">
        Our new Smart Feature Technology runs innovative <br /> features and
        adds upgrades via the Skullcandy App. <br /> The future is now.
      </p>
      <button className={style.btn}>SHOP SKULL-IQ</button>
    </div>
  );
}

export default Adverstingone;

// Our new Smart Feature Technology runs innovative features and adds upgrades via the Skullcandy App. The future is now.

// SHOP SKULL-IQ
