import React from "react";

function Header() {
  const style = {
    Header:
      "w-[1024px] h-[30px] bg-[#000] flex justify-center items-center absolute top-[58px] lg:w-[1665px]",
  };
  return (
    <div className={style.Header}>
      <p className="text-white text-[14px]">Free Shipping over $100</p>
    </div>
  );
}

export default Header;
