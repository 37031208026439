import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { ProductContext } from "../context/ProductContext";

function Singlepage() {
  const location = useLocation();
  const product = location.state;
  console.log(location.state);
  const { state, dispatch } = useContext(ProductContext);
  console.log(location.state);
  const AddProduct = (item) => {
    dispatch({ type: "ADD__CART", payload: item });
  };
  document.title = product.data.productName;
  const style = {
    father: "w-[100%] h-[120vh] flex bg-[#000] text-white",
    left: "w-[50%] h-[100%] pt-[240px]",
    right: "w-[50%] h-[100%] pt-[80px] fixed left-[900px] top-[50px]",
    name: "text-[55px] font-bold w-[90%] text-white ml-[-90px]",
    desc: "text-[36px] font-semibold w-[50%] text-left text-white ml-[65px]",
    colors:
      "w-[65%] ml-[65px] mt-[20px] flex flex-wrap justify-between items-center",
    red: "w-[48%] mb-[15px] h-[140px] cursor-pointer text-[24px] bg-[#767676] duration-[.5s] hover:bg-[crimson] flex justify-center items-center",
    green:
      "w-[48%] h-[140px] mb-[15px] cursor-pointer text-[24px] bg-[#767676] duration-[.5s] hover:bg-[green] flex justify-center items-center",
    blue: "w-[48%] h-[140px] cursor-pointer text-[24px] bg-[#767676] duration-[.5s] hover:bg-[blue] flex justify-center items-center",
    btn: "w-[490px] ml-[-215px] mt-[20px] h-[55px] bg-[#ddd] text-[#000]",
  };
  return (
    <div>
      <Navbar />
      <div className={style.father} key={product.id}>
        <div className={style.left}>
          <h1 className={style.name}>{product.data.productName}</h1>
          <h3 className={style.desc}>{product.data.productDescription}</h3>
          <div className={style.colors}>
            <div className={style.red}>
              <p>Red</p>
            </div>
            <div className={style.green}>
              <p>Green</p>
            </div>
            <div className={style.blue}>
              <p>Blue</p>
            </div>
          </div>
          {state.products?.some((it) => it.id === product.id) ? (
            <button className={style.btn}>
              <Link to={"/cart"}>ADD TO CART</Link>
            </button>
          ) : (
            <button className={style.btn} onClick={() => AddProduct(product)}>
              ADD TO CART
            </button>
          )}
        </div>
        <div className={style.right}>
          <img src={product.data.productImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Singlepage;

// import { ProductContext } from "../context/ProductContext";
// const { state, dispatch } = useContext(ProductContext);
// const location = useLocation();
// const product = location.state;
// console.log(location.state);
// const AddProduct = (item) => {
//   dispatch({ type: "ADD__CART", payload: item });
// };
