import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCjqLSNW4-GjBUsAqqmKtdbBlNd0R9oju4",
  authDomain: "skullcandy-9541e.firebaseapp.com",
  projectId: "skullcandy-9541e",
  storageBucket: "skullcandy-9541e.appspot.com",
  messagingSenderId: "263419377061",
  appId: "1:263419377061:web:08f20d67c4822ac2ed244c",
  measurementId: "G-2W66E1PTKJ",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const firestore = getFirestore();
